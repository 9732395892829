import { Alert, Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { BillingDetailsInformation } from "../interfaces/BillingDetails";
import { useEffect, useState, useMemo } from "react";
import { dropRight, join, last, split } from "lodash";
import { PLAN_MODE } from "../consts/Plans";
import { getSKMainPrice, getSKExtraPrice, getCLOnlyPrice, getCurrencyFromCountry, getPlanModeFromProductId, getOneTimePackagePrice, getAutomationPrice } from "./payment/utils";
import Coupon from "../interfaces/Coupon";
import LoadingButton from "./LoadingButton";
import PaymentHandler from "../classes/PaymentHandler";
import PaymentIntent from "../interfaces/PaymentIntent";
import { useStripe } from "@stripe/react-stripe-js";
import Subscription from "../interfaces/Subscription";
import { OverAllSubscription } from "../interfaces/OverAllSubscription";

type Props = {
    isOpen: boolean,
    paymentMethods: BillingDetailsInformation[] | null,
    productId: string | undefined,
    planMode: PLAN_MODE.PSK_CL | PLAN_MODE.CL | PLAN_MODE.PURCHASE_CREDITS | PLAN_MODE.AUTOMATION,
    country: string,
    serverUrl: string | undefined,
    userId: number | undefined,
    usedFirstAffiliate: boolean,
    referredCode?: string | number,
    activeSubscription: OverAllSubscription | null,
    pmAccountCount: number,
    customerId: string | undefined,
    toggle: () => void,
    onPaymentSuccess: () => void,
    onUpgradedSubscription: (subscription: OverAllSubscription) => void
}

const ContinueWithPaymentMethodModal = ({ isOpen, userId, usedFirstAffiliate, referredCode, paymentMethods, productId, planMode, country, serverUrl, activeSubscription, pmAccountCount, customerId, toggle, onPaymentSuccess, onUpgradedSubscription }: Props) => {
    const [alert, setAlert] = useState("");
    const [couponCode, setCouponCode] = useState('');
    const [couponDetails, setCouponDetails] = useState<Coupon | null>(null);
    const [isOpenDropdown, setIsOpenDropdown] = useState(false);
    const [activePaymentMethod, setActivePaymentMethod] = useState<BillingDetailsInformation | undefined>(undefined);
    const [isPaying, setIsPaying] = useState(false);
    const stripe = useStripe();
    const amountToPay = useMemo(() => {
        const splittedProdId = split(productId, "_");
        const count = last(splittedProdId);
        if (!count || !productId) {
            return 0;
        }
        const planId = join(dropRight(splittedProdId), '_');
        if (planMode === PLAN_MODE.PSK_CL) {
            return getSKMainPrice(parseInt(count), country) + getSKExtraPrice(planId, country);
        } else if (planMode === PLAN_MODE.CL) {
            return getCLOnlyPrice(planId, country)
        } else if (planMode === PLAN_MODE.PURCHASE_CREDITS) {
            return getOneTimePackagePrice(planId, country, parseInt(count));
        } else if (planMode === PLAN_MODE.AUTOMATION) {
            return getAutomationPrice(productId, country);
        }
        return 0;
    }, [productId, planMode, country]);

    const totalPrice = useMemo(() => {
        let totalPrice = amountToPay;
        if (couponDetails) {
            if(couponDetails.percent_off) totalPrice = totalPrice * (100 - couponDetails.percent_off) / 100;
            if(couponDetails.amount_off) totalPrice = totalPrice - couponDetails.amount_off;
        }
        return totalPrice;
    }, [amountToPay, couponDetails]);

    useEffect(() => {
        if (!!paymentMethods?.length) {
            setActivePaymentMethod(paymentMethods?.find(pM => !!pM.isDefault) ?? paymentMethods?.[0])
        } else {
            setActivePaymentMethod(undefined);
        }
    }, [paymentMethods]);

    const onConfirm = async () => {
        if (!serverUrl || !userId || !productId) return;
        try {
            setIsPaying(true);
            const paymentHandler = new PaymentHandler<Coupon | PaymentIntent | Subscription>(serverUrl);    
            let couponDetails;
            if (!!couponCode) {
                couponDetails = await paymentHandler.getCouponDetails(couponCode, customerId, productId);
                setCouponDetails(couponDetails);
            }
            
            if (activeSubscription?.productId && getPlanModeFromProductId(activeSubscription?.productId) === getPlanModeFromProductId(productId)) {
                if (activeSubscription.productId === productId) {
                    window.alert('This is the plan you already purchased.');
                    return;
                }
                if (productId.includes("sidekick")) {
                    const [,,count] = productId.split("_");
                    if (parseInt(count) < pmAccountCount) {
                        window.alert('You need to unlink some poshmark closets first.')
                        return;
                    }
                }
                
                if (!activeSubscription.subscriptionId) {
                    window.alert('You dont have active subscription. Please refresh the page and try again.');
                    return;
                }

                const upgradedSub = await paymentHandler.upgradeSubscription(userId, activeSubscription.subscriptionId, productId);
                onUpgradedSubscription(upgradedSub)
            } else {
                let paymentIntent;
                if (planMode === PLAN_MODE.PSK_CL || planMode === PLAN_MODE.CL || planMode === PLAN_MODE.AUTOMATION) {
                    paymentIntent = await paymentHandler.fetchSubscriptionPaymentIntent(userId, productId, usedFirstAffiliate, referredCode, !!couponDetails?.id ? couponCode : undefined, country === 'GB' ? 'GBP' : 'USD');
                } else {
                    let amountToPay = totalPrice
                    if (!!couponDetails) {
                        if(couponDetails.percent_off) amountToPay = amountToPay * (100 - couponDetails.percent_off) / 100;
                        if(couponDetails.amount_off) amountToPay = amountToPay - couponDetails.amount_off;
                    }
                    amountToPay = Math.floor(amountToPay);
                    paymentIntent = await paymentHandler.fetchOneOffPackagePaymentIntent(userId, amountToPay, country === 'GB' ? 'GBP' : 'USD', { productId });
                }
                
                if (paymentIntent.clientSecret) {
                    const paymentResult = await stripe?.confirmCardPayment(paymentIntent.clientSecret, {
                        payment_method: activePaymentMethod?.id,
                        setup_future_usage: 'off_session'
                    })
                    if (paymentResult?.error) {
                        setAlert(paymentResult.error.message ?? "Payment error");
                        return;
                    }
                    if (paymentResult?.paymentIntent) {
                        onPaymentSuccess();
                    }
                }
            }
        } catch (e) {
            if (e instanceof Error) {
                setAlert(e.message);
            } else if (typeof e === 'string') {
                setAlert(e);
            }
        } finally {
            setIsPaying(false);
        }
    }

    return (
        <Modal isOpen={isOpen} centered>
            <ModalHeader>Confirm Payment</ModalHeader>
            <ModalBody>
                <h6 className="lh-lg">Are you sure you want to pay {getCurrencyFromCountry(country)}{(totalPrice / 100).toFixed(2)} with your {activePaymentMethod?.card.brand} credit card ending ****{activePaymentMethod?.card.last4}?</h6>
                {
                    paymentMethods && paymentMethods?.length > 1 && 
                    <div className="d-flex justify-content-between align-items-center">
                        <h6>Payment Methods</h6>
                        <Dropdown isOpen={isOpenDropdown} toggle={() => setIsOpenDropdown(prev => !prev)} direction="down">
                            <DropdownToggle caret className="bg-transparent border-1 text-muted">{activePaymentMethod?.card.brand} ends in {activePaymentMethod?.card.last4}</DropdownToggle>
                            <DropdownMenu>
                                {paymentMethods?.map(method => (
                                    <DropdownItem key={method.id} onClick={() => setActivePaymentMethod(method)}>{method?.card.brand} ends in {method?.card.last4}</DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                }
                <Input placeholder="Coupon Code (Optional)" className="mt-2" value={couponCode} onChange={e => setCouponCode(e.target.value)} />
                {
                    !!alert && <Alert color="danger" className="mt-2" toggle={() => setAlert("")}>{alert}</Alert>
                }
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>No</Button>
                <LoadingButton className="w-auto" color="danger" onClick={onConfirm} loading={isPaying}>Yes</LoadingButton>
            </ModalFooter>
        </Modal>
    )
}

export default ContinueWithPaymentMethodModal;