import { useEffect, useMemo, useState } from "react";
import ReactSlider from "react-slider";
import { Col, Container, Row, Card, CardHeader, CardBody, Tooltip, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import LoadingButton from "../LoadingButton";
import { CL_RELISTING_FEATURES, PLAN_MODE, PLAN_TITLES, PSK_MAIN_FEATURES, PSK_OTHER_FEATURES } from "../../consts/Plans";
import _, { snakeCase } from "lodash";
import { getCLOnlyPrice, getPlanModeFromProductId, getSKExtraPrice, getSKMainPrice, getOneTimePackagePrice, getAutomationPrice } from "./utils";
import { PSKUserInfo } from "../../interfaces/UserInfo";

type Props = {
    title: string,
    onClkPayNow: (title: string, count: number) => void,
    features: { [key: string]: string | number [] },
    isPaying: boolean,
    country: string,
    planMode: string,
    subscribedProductId?: string | undefined,
    targetProductId?: string | null,
    pskUser?: PSKUserInfo | null
}
const PlanCard = ({ title, subscribedProductId, targetProductId, features, onClkPayNow, isPaying, country, planMode, pskUser }: Props) => {
    const [count, setCount] = useState(1); // 1 - 29.99 2 - 49.99 3 - 74.99 4 - 99 5 - 124.99
    const [pskFeaturesToolTipOpen, setPskFeaturesToolTipOpen] = useState(false);
    const [clFeaturesToolTipOpen, setCLFeaturesToolTipOpen] = useState(false);
    const [optionCount, setOptionCount] = useState(0);
    const [optionCountOpen, setOptionCountOpen] = useState(false);

    useEffect(() => {
        if (planMode === PLAN_MODE.CL) {
            setOptionCount(10);
        } else if (planMode === PLAN_MODE.PURCHASE_CREDITS) {
            if (title === PLAN_TITLES.STOCK_PHOTO) {
                setOptionCount(50);
            } else if (title === PLAN_TITLES.AUCTION) {
                setOptionCount(21);
            }
        } else {
            setOptionCount(0);
        }
    }, [title, planMode]);

    useEffect(() => {
        if (subscribedProductId) {
            const [piece1, piece2, count] = subscribedProductId.split("_");
            if (_.toLower((piece1 + " " + piece2)) === _.toLower(title)) {
                if (getPlanModeFromProductId(subscribedProductId) === PLAN_MODE.PSK_CL) {
                    setCount(parseInt(count));
                } else {
                    setOptionCount(parseInt(count))
                }
            }
            
        }
    }, [subscribedProductId, title]);

    const buttonTitle = useMemo(() => {
        if (!subscribedProductId) {
            return "Buy Now";
        }
        const [p1, p2, slotCount] = subscribedProductId.split("_");
        if (_.toLower(p1 + " " + p2) !== _.toLower(title)) {
            return "Buy Now";
        }
    
        if (getPlanModeFromProductId(subscribedProductId) === PLAN_MODE.PSK_CL) {
            if (count > parseInt(slotCount)) {
                return "Upgrade";
            }
            if (count < parseInt(slotCount)) {
                return "Downgrade";
            }
        } else if (getPlanModeFromProductId(subscribedProductId) === PLAN_MODE.CL){
            if (optionCount > parseInt(slotCount)) {
                return "Upgrade";
            }
            if (optionCount < parseInt(slotCount)) {
                return "Downgrade";
            }
        }
        
        return "Current Plan";
    }, [count, optionCount, subscribedProductId, title]);

    const isCurrentPlan = useMemo(() => {
        if (!subscribedProductId) {
            return false;
        }
        const [p1, p2, slotCount] = subscribedProductId.split("_");
        if (_.toLower(p1 + " " + p2) === _.toLower(title)) {
            if (getPlanModeFromProductId(subscribedProductId) === PLAN_MODE.CL && optionCount === parseInt(slotCount)) {
                return true;
            }
            if (getPlanModeFromProductId(subscribedProductId) === PLAN_MODE.PSK_CL && count === parseInt(slotCount)) {
                return true;
            }
            if (getPlanModeFromProductId(subscribedProductId) === PLAN_MODE.PURCHASE_CREDITS) {
                return true;
            }
            if (getPlanModeFromProductId(subscribedProductId) === PLAN_MODE.AUTOMATION) {
                return true;
            }
        }
        
        return false;
    }, [count, optionCount, subscribedProductId, title]);

    const isSubscribingPlan = useMemo(() => {
        if (!targetProductId) {
            return false;
        }
        const [p1, p2,] = targetProductId.split("_");
        if (_.toLower(p1 + " " + p2) === _.toLower(title)) {
            return true;
        }
        return false;
    }, [targetProductId, title]);

    const getCurrency = () => {
        if (country === 'GB') {
            return '£';
        }
        return '$';
    }

    const mainPrice = useMemo(() => {
        if (planMode === PLAN_MODE.PSK_CL) {
            return getSKMainPrice(count, country);
        } else if (planMode === PLAN_MODE.CL) {
            return getCLOnlyPrice(snakeCase(title), country);
        } else if (planMode === PLAN_MODE.PURCHASE_CREDITS) {
            return getOneTimePackagePrice(snakeCase(title), country, optionCount)
        } else if (planMode === PLAN_MODE.AUTOMATION) {
            return getAutomationPrice(snakeCase(title), country);
        }
        return 0;
    }, [planMode, title, country, count, optionCount]);

    const extraPrice = useMemo(() => {
        return getSKExtraPrice(snakeCase(title), country);
    }, [title, country])

    const onChangeSlider = (newVal: number, newIndex: number) => {
        setCount(newVal);
    }

    const psKFeaturesToolTip = useMemo(() => {
        return (
            <Tooltip placement="right" isOpen={pskFeaturesToolTipOpen} target={_.snakeCase(title) + "_showTooltip"} toggle={() => setPskFeaturesToolTipOpen(prev => !prev)}>
                <h6><strong>Main Features</strong></h6>
                {PSK_MAIN_FEATURES.map(feature => (
                    <h6 key={feature}>{feature}</h6>
                ))}
                <br />
                <h6><strong>Other Features</strong></h6>
                {PSK_OTHER_FEATURES.map(feature => (
                    <h6 key={feature}>{feature}</h6>
                ))}
            </Tooltip>
        )
    }, [title, pskFeaturesToolTipOpen]);

    const clRelistingFeaturesToolTip = useMemo(() => {
        return (
            <Tooltip placement="right" isOpen={clFeaturesToolTipOpen} target={_.snakeCase(title) + "_showTooltip"} toggle={() => setCLFeaturesToolTipOpen(prev => !prev)}>
                <h6><strong>Main Features</strong></h6>
                {CL_RELISTING_FEATURES.map(feature => (
                    <h6 key={feature}>{feature}</h6>
                ))}
            </Tooltip>
        )
    }, [title, clFeaturesToolTipOpen]);

    const onClickPayNow = () => {
        if (planMode === PLAN_MODE.PSK_CL) {
            onClkPayNow(title, count) ;
        } else if (planMode === PLAN_MODE.CL) {
            onClkPayNow(title, optionCount);
        } else if (planMode === PLAN_MODE.PURCHASE_CREDITS) {
            onClkPayNow(title, optionCount);
        } else if (planMode === PLAN_MODE.AUTOMATION) {
            onClkPayNow(title, optionCount);
        }
    }

    const getSubTitle = () => {
        if (planMode === PLAN_MODE.PSK_CL || planMode === PLAN_MODE.CL) {
            return 'Per month';
        } else {
            if (title === PLAN_TITLES.STOCK_PHOTO) {
                return `Available Credits: ${pskUser?.stockPhotoCredits}`
            } else if (title === PLAN_TITLES.AUCTION) {
                return `Available Credits: ${pskUser?.extraAuctionCredits}`
            }
        }
    }

    return (
        <Card>
            <CardHeader style={{ fontSize: '20px', fontWeight: 'bold' }}>
                {title}
            </CardHeader>
            <CardBody>
                <div className="d-inline">
                    <h5 className="d-inline" style={{ color: '#3A5579' }}><strong>{getCurrency()}{(mainPrice / 100).toFixed(2)}</strong></h5>
                    {
                        planMode === PLAN_MODE.PSK_CL && title !== PLAN_TITLES.PSK && 
                        <h6 className="d-inline"> + {getCurrency()}{(extraPrice / 100).toFixed(2)} = </h6>
                    }
                    {
                        planMode === PLAN_MODE.PSK_CL && title !== PLAN_TITLES.PSK && 
                        <h6 className="d-inline" style={{ color: '#3A5579' }}><strong>{getCurrency()}{((mainPrice + extraPrice) / 100).toFixed(2)}</strong></h6>
                    }
                </div>
                <h6>{getSubTitle()}</h6>
                {
                    planMode === PLAN_MODE.PSK_CL && <h5 className="mt-4"><strong>Number of Posh Closet(s): {count}</strong></h5>
                }
                {
                    planMode === PLAN_MODE.PSK_CL && 
                    <ReactSlider
                        value={count === 0 ? 1 : count}
                        onChange={onChangeSlider}
                        className="horizontal-slider"
                        thumbClassName="slider-thumb"
                        trackClassName="slider-track"
                        max={5}
                        min={1}
                    />
                }
                <LoadingButton onClick={onClickPayNow} disabled={isPaying || isCurrentPlan} loading={isPaying && isSubscribingPlan}>
                    <h6 className="mb-0">{buttonTitle}</h6>
                </LoadingButton>
                <Container className="px-0" style={{ marginTop: '15px' }}>
                    {Object.entries(features).map(([key, value], index) => (
                        <Row key={key} className="mt-3 align-items-center">
                            <Col xs='6'>
                                <h6 className="mb-0" id={_.snakeCase(title) + (((planMode === PLAN_MODE.PSK_CL && index === 0) || (planMode === PLAN_MODE.CL && index === 2)) ? "_showTooltip" : "")}>{key} : </h6>
                                {((planMode === PLAN_MODE.PSK_CL && index === 0) || (planMode === PLAN_MODE.CL && index === 2)) && <small>(Tap to see details)</small>}
                                {planMode === PLAN_MODE.PSK_CL && index === 0 && psKFeaturesToolTip}
                                {planMode === PLAN_MODE.CL && index === 2 && clRelistingFeaturesToolTip}
                            </Col>
                            <Col xs='6'>
                                {
                                    typeof value === 'string' ? 
                                    <h6 className="mb-0"><strong>{value}</strong></h6> : 
                                    <Dropdown isOpen={optionCountOpen} toggle={() => setOptionCountOpen(prev => !prev)} direction="down">
                                        <DropdownToggle caret className='bg-transparent border-1 text-muted'>{optionCount}</DropdownToggle>
                                        <DropdownMenu>
                                            {value.map(option => (
                                                <DropdownItem key={option} onClick={() => setOptionCount(option)}>{option}</DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </Dropdown>
                                }
                            </Col>
                        </Row>
                    ))}
                </Container>
            </CardBody>
        </Card>
    )
}

export default PlanCard;