import APIHandler from "./APIHandler";
import ExceptionHandler from "./ExceptionHandler";
import PaymentIntent from "../interfaces/PaymentIntent";
import Subscription from "../interfaces/Subscription";
import { Endpoints } from "../consts/Endpoints";
import Coupon from "../interfaces/Coupon";
import { OverAllSubscription } from "../interfaces/OverAllSubscription";
import { BillingDetailsInformation } from "../interfaces/BillingDetails";

type PaymentResponses = PaymentIntent | Subscription | Subscription[] | Coupon | BillingDetailsInformation;
export default class PaymentHandler<T extends PaymentResponses> extends ExceptionHandler {
    readonly apiHandler: APIHandler<T>;
    constructor(baseURL: string) {
        super();
        this.apiHandler = new APIHandler<T>(baseURL);
    }

    applyCoupon = async (subscriptionId: string, coupon: string) => {
        try {
            const params = {
                subscriptionId,
                coupon
            };
            const subscription = await this.apiHandler.invokePOST(Endpoints.URL_APPLY_COUPON, params);
            return subscription as any;
        } catch (e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }

    fetchSubscriptionPaymentIntent = async (userId: number, productId: string, usedFirstAffiliate: boolean, referredCode?: string | number, couponCode?: string, currency: string = 'USD') => {
        try {
            const removeSpaceCouponCode = couponCode ? couponCode.replace(/\s/g, '') : couponCode;
            const params = {
                productId,
                userId,
                isWebPayment: true,
                couponCode: removeSpaceCouponCode,
                currency,
                referredCode,
                usedFirstAffiliate
            }
            const paymentIntent = await this.apiHandler.invokePOST(Endpoints.CREATE_SUBSCRIPTION, params)
            return paymentIntent as PaymentIntent;
        } catch (e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }

    fetchOneOffPackagePaymentIntent = async (userId: number, amount: number, currency: string = 'USD', metadata: any = null) => {
        try {
            const params = {
                userId,
                amount,
                currency,
                metadata
            }
            const paymentIntent = await this.apiHandler.invokePOST(Endpoints.CREATE_PAYMENT_INTENT, params);
            return paymentIntent as PaymentIntent;
        } catch (e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }

    getNetworkShareSubscrition = async (pmUserId: string) => {
        try {
            const params = {
                pmUserId
            }
            const subscription = await this.apiHandler.invokeGET(Endpoints.GET_NETWORK_SHARE_SUBSCRIPTION, params);
            return subscription;
        } catch (e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }

    getSubscription = async (userId: number) => {
        try {
            const params = {
                userId
            }
            const subscription = await this.apiHandler.invokeGET(Endpoints.GET_STRIPE_SUBSCRIPTION, params);
            return subscription as OverAllSubscription;
        } catch (e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }

    getSubscriptionV2 = async (userId: number) => {
        try {
            // const subscription = await this.apiHandler.invokeGET(Endpoints.GET_SUBSCRIPTION, userId);
            const subscription = await this.apiHandler.apiInstance.get(`api/merge/subscription/${userId}`);
            return subscription.data as OverAllSubscription[];
        } catch (e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }

    getPaymentDetails = async (userId: number) => {
        try {
            // const subscription = await this.apiHandler.invokeGET(Endpoints.GET_SUBSCRIPTION, userId);
            const subscription = await this.apiHandler.apiInstance.get(`api/merge/subscription/payment-methods/${userId}`);
            return subscription.data as Array<BillingDetailsInformation>;
        } catch (e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }

    payNowSubscription = async (paymentIntentId: string, paymentMethodId: string, type: "promoted" | "normal") => {
        const url = type === "normal" ? `api/merge/subscription/retry` : `api/merge/subscription/retry/promoted`; 
        try {
            const subscription = await this.apiHandler.apiInstance.post(url, { paymentIntentId, paymentMethodId });
            return subscription.data as any;
        } catch (e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }


    getCouponDetails = async (coupon: string, customerId?: string, priceId?: string) => {
        try {
            if (!coupon) {
                throw new Error('Coupon could not be null');
            }
            const params = {
                coupon,
                customerId,
                priceId
            }
            const couponDetails = await this.apiHandler.invokeGET(Endpoints.GET_COUPON_DETAILS, params);
            return couponDetails as Coupon;
        } catch (e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }


    upgradeSubscription = async (userId: number, subscriptionId: string, productId: string) => {
        try {
            if (!subscriptionId) {
                throw new Error('Subscription Id is required');
            }
            if (!productId) {
                throw new Error('Product Id is required');
            }
            const params = {
                userId,
                subscriptionId,
                productId
            };
            const subscription = await this.apiHandler.invokePOST(Endpoints.UPDATE_SUBSCRIPTION, params);
            return subscription as OverAllSubscription;
        } catch (e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }

    attachNewPayment = async (sCusId: string, paymentMethodId: string) => {
        try {
            const paymentMethod = await this.apiHandler.invokePOST(Endpoints.ATTACH_NEW_PAYMENT_METHOD, { sCusId, paymentMethodId })
            return paymentMethod as BillingDetailsInformation;
        } catch (e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }

    removeCard = async (userId: number, cardId: string) => {
        try {
            const subscription = await this.apiHandler.apiInstance.get(`api/merge/subscription/payment-methods/${userId}/remove/${cardId}`);
            return subscription as any;
        } catch (e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }

    defaultCard = async (userId: number, cardId: string) => {
        try {
            const subscription = await this.apiHandler.apiInstance.get(`api/subscription/payment-methods/${userId}/default/${cardId}`);
            return subscription as any;
        } catch (e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }

    cancelSubscription = async (userId: number, subscriptionId: string, reason?: Array<{ reason: string, answer?: string }>) => {
        try {
            if (!userId) {
                throw new Error('User Id is required');
            }
            const params = {
                userId,
                subscriptionId,
                reason
            }
            await this.apiHandler.invokePOST(Endpoints.UNSUBSCRIBE, params);
        } catch (e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }

    cancelSubscriptionNetworkShare = async (pmUserId: string, reason: Array<{ reason: string, answer?: string }>) => {
        try {
            if (!pmUserId) {
                throw new Error('User Id is required');
            }
            const params = {
                pmUserId,
                reason
            }
            await this.apiHandler.invokePOST(Endpoints.UNSUBSCRIBE_NETWORK_SHARE, params);
        } catch (e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }
}