import React from 'react';
import { 
    Container, 
    Card, 
    CardBody, 
    CardFooter, 
    CardHeader, 
    Row, 
    Col,
    Input,
    Button,
    Alert
} from 'reactstrap';
import logoIcon from '../assets/logo_onboard.png';
import { useState } from 'react';
import LoadingButton from '../components/LoadingButton';
import { PSKUserInfo } from '../interfaces/UserInfo';
import AuthHandler from '../classes/AuthHandler';
import { useNavigate } from 'react-router-dom';
import InputCodeModalEmail from '../components/login/InputCodeModalEmail';

const SignUp = () => {
    const [alert, setAlert] = useState('');
    const [signingUp, setSigningUp] = useState(false);
    const [userInfo, setUserInfo] = useState<PSKUserInfo>({ email: '', firstname: '', lastname: '', phone: '', password: '', confirmPassword: '', referralCode: undefined });
    const [openCodeModal, setOpenCodeModal] = useState(false);
    const navigate = useNavigate();

    const onClkSignUp = async () => {
        //go to google.com
        window.location.href = 'https://poshsidekick.com/';
    }

    const onVerifySuccess = async () => {
        try {
            setSigningUp(true);
            setOpenCodeModal(false);
            const authHandler = new AuthHandler();
            const user = await authHandler.userRegister(userInfo);
            navigate('/payment', { state: user });
            setSigningUp(false);
        } catch(e) {
            if (e instanceof Error) {
                setAlert(e.message);
            } else if (typeof e === 'string') {
                setAlert(e);
            }
            setSigningUp(false);
        }
    }

    return (
        <Container className='my-5'>
            <Row>
                <Col lg='6' className='mx-auto'>
                    <Card>
                        <CardHeader>
                            <img alt='...' src={logoIcon} width={239} height={66} />
                        </CardHeader>
                        <CardBody className='p-3'>
                            <h4><strong>Create an Account</strong></h4>
                            <Input className='mt-2' 
                                placeholder='First Name' 
                                value={userInfo.firstname} 
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserInfo(prev => ({ ...prev, firstname: e.target.value }))} />
                            <Input className='mt-2' 
                                placeholder='Last Name'
                                value={userInfo.lastname}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserInfo(prev => ({ ...prev, lastname: e.target.value }))} />
                            {/* <PhoneInput 
                                className='mt-2'
                                defaultCountry='US'
                                inputComponent={ForwardedInput}
                                international
                                withCountryCallingCode
                                value={userInfo.phone}
                                onChange={e => setUserInfo(prev => ({ ...prev, phone: e }))}
                            /> */}
                            <Input className='mt-2' 
                                placeholder='Email'
                                value={userInfo.email}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserInfo(prev => ({ ...prev, email: e.target.value }))} />
                            <Input className='mt-2' 
                                placeholder='Password' 
                                type='password'
                                value={userInfo.password}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserInfo(prev => ({ ...prev, password: e.target.value }))} />
                            <Input className='mt-2' 
                                placeholder='Confirm Password'
                                type='password'
                                value={userInfo.confirmPassword}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserInfo(prev => ({ ...prev, confirmPassword: e.target.value }))} />
                            <Input className='mt-2' 
                                placeholder='Referral Code (Optional)'
                                value={userInfo.referralCode}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserInfo(prev => ({ ...prev, referralCode: e.target.value }))} />
                            {
                                !!alert && <Alert color='danger' className='mt-2' toggle={() => setAlert('')}>{alert}</Alert>
                            }
                        </CardBody>
                        <CardFooter>
                            <LoadingButton className='mt-2' loading={signingUp} disabled={signingUp} onClick={onClkSignUp}>
                                <h6 className='mb-0 text-white'>Sign Up</h6>
                            </LoadingButton>
                            <div className='d-flex justify-content-center align-items-center'>
                                <small>Already have an account?</small>
                                <Button color='link'><a href='/'>Login</a></Button>
                            </div>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
            {
                openCodeModal && <InputCodeModalEmail isOpen={openCodeModal} email={userInfo.email} toggle={() => setOpenCodeModal(false)} onVerifySuccess={onVerifySuccess} />
            }
        </Container>
    )
}

export default SignUp;