import { snakeCase } from "lodash"
import { PLAN_MODE, PLAN_TITLES } from "../../consts/Plans"

export const getSKMainPrice = (count: number, country: string) => {
    if (count === 1) {
        if (country === 'US') {
            return 2999
        } else if (country === 'GB') {
            return 2339
        }
    } else if (count === 2) {
        if (country === 'US') {
            return 5499
        } else if (country === 'GB') {
            return 4289
        }
    } else if (count === 3) {
        if (country === 'US') {
            return 7499
        } else if (country === 'GB') {
            return 5849
        }
    } else if (count === 4) {
        if (country === 'US') {
            return 8999
        } else if (country === 'GB') {
            return 7019
        }
    } else if (count === 5) {
        if (country === 'US') {
            return 10499
        } else if (country === 'GB') {
            return 8189
        }
    }
    return 0;
}

export const getSKExtraPrice = (planId: string, country: string) => {
    if (planId === snakeCase(PLAN_TITLES.SK_BASIC)) {
        if (country === 'US') {
            return 1500
        } else if (country === 'GB') {
            return 1200
        }
    } else if (planId === snakeCase(PLAN_TITLES.SK_SILVER)) {
        if (country === 'US') {
            return 2000
        } else if (country === 'GB') {
            return 1600
        }
    } else if (planId === snakeCase(PLAN_TITLES.SK_GOLD)) {
        if (country === 'US') {
            return 3000
        } else if (country === 'GB') {
            return 2400
        }
    } else if (planId === snakeCase(PLAN_TITLES.SK_PRO)) {
        if (country === 'US') {
            return 4500
        } else if (country === 'GB') {
            return 3600
        }
    } else if (planId === snakeCase(PLAN_TITLES.SK_ENTERPRISE)) {
        if (country === 'US') {
            return 7000
        } else if (country === 'GB') {
            return 5600
        }
    }
    return 0;
}

export const getCLOnlyPrice = (planId: string, country: string) => {
    if (planId === snakeCase(PLAN_TITLES.CL_BASIC)) {
        if (country === 'US') {
            return 999;
        } else if (country === 'GB') {
            return 799;
        }
    } else if (planId === snakeCase(PLAN_TITLES.CL_SILVER)) {
        if (country === 'US') {
            return 1999;
        } else if (country === 'GB') {
            return 1599;
        }
    } else if (planId === snakeCase(PLAN_TITLES.CL_GOLD)) {
        if (country === 'US') {
            return 2999;
        } else if (country === 'GB') {
            return 2399;
        }
    } else if (planId === snakeCase(PLAN_TITLES.CL_PRO)) {
        if (country === 'US') {
            return 3999;
        } else if (country === 'GB') {
            return 3199;
        }
    } else if (planId === snakeCase(PLAN_TITLES.CL_ENTERPRISE)) {
        if (country === 'US') {
            return 4499;
        } else if (country === 'GB') {
            return 3599;
        }
    }
    return 0;
}

export const getAutomationPrice = (planId: string, country: string) => {
    if (planId === snakeCase(PLAN_TITLES.AUTOMATION_MCR)) {
        if (country === 'US') {
            return 1199;
        } else if (country === 'GB') {
            return 959;
        }
    }
    return 0;
}

export const getOneTimePackagePrice = (planId: string, country: string, credits: number) => {
    if (planId === snakeCase(PLAN_TITLES.STOCK_PHOTO)) {
        if (country === 'US') {
            if (credits === 50) {
                return 999;
            } else if (credits === 125) {
                return 1999;
            }
        } else if (country === 'GB') {
            if (credits === 50) {
                return 799;
            } else if (credits === 125) {
                return 1599
            }
        }
    } else if (planId === snakeCase(PLAN_TITLES.AUCTION)) {
        if (country === 'US') {
            if (credits === 21) {
                return 999;
            } else if (credits === 42) {
                return 1799;
            }
        } else if (country === 'GB') {
            if (credits === 21) {
                return 799;
            } else if (credits === 250) {
                return 1399
            }
        }
    }
    return 0;
}

export const getPlanModeFromProductId = (productId: string) => {
    if (productId.includes("crosslisting")) {
        return PLAN_MODE.CL;
    } else if (productId.includes("sidekick")) {
        return PLAN_MODE.PSK_CL;
    } else if (productId.includes("stock")) {
        return PLAN_MODE.PURCHASE_CREDITS;
    } else if (productId.includes("automation")) {
        return PLAN_MODE.AUTOMATION;
    }
    return null;
}

export const getCurrencySymbol = (currencyName: string) => {
    if (currencyName === 'gbp' || currencyName === 'GBP') {
        return '£';
    }
    return '$';
}

export const getCurrencyFromCountry = (country: string) => {
    if (country === 'GB' || country === 'UK') {
        return '£'
    }
    return '$';
}