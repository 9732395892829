// import AuthResponse from "../interfaces/AuthResponse";
import APIHandler from "./APIHandler";
import validator from "validator";
import { Endpoints } from "../consts/Endpoints";
import ExceptionHandler from "./ExceptionHandler";
import parsePhoneNumberFromString from "libphonenumber-js/min";
import UserInfo, { PSKUserInfo } from "../interfaces/UserInfo";

class AuthHandler extends ExceptionHandler {
    readonly apiHandler: APIHandler<UserInfo>;
    constructor() {
        super();
        let authServerUrl = process.env.REACT_APP_SERVER_URL;
        if (!authServerUrl) {
            authServerUrl = "https://dev-auth.crosslisting.com/"
        }
        this.apiHandler = new APIHandler(process.env.REACT_APP_SERVER_URL as string);
        this.apiHandler.apiInstance.interceptors.request.use(config => {
            return {
                ...config,
                headers: {
                    ...config.headers,
                    "x-app-id": "com.ppt.poshsidekick"
                }
            }
        })
    }

    private validPhoneNumber = (phoneNumber?: string): boolean => {
        if (!phoneNumber) {
            return false;
        }
        
        const parsedNumber = parsePhoneNumberFromString(phoneNumber);
        if (!parsedNumber || !parsedNumber.isValid()) {
            return false;
        }
        return true;
    }

    private validEmail = (email?: string): boolean => {
        if (!email || !validator.isEmail(email)) {
            return false;
        }
        return true;
    }

    userRegister = async (userInfo: PSKUserInfo) => {
        if (!userInfo.firstname || !userInfo.lastname) {
            throw new Error('Invalid name detected');
        }
        if (!this.validEmail(userInfo.email)) {
            throw new Error('Invalid email detected');
        }
        if (!userInfo.password) {
            throw new Error('Invalid password detected');
        }
        if (userInfo.password !== userInfo.confirmPassword) {
            throw new Error('Password does not match');
        }
        try {
            const user = await this.apiHandler.invokePOST(Endpoints.USER_REGISTER, userInfo)
            return user as UserInfo;
        } catch(e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }

    loginWithEmail = async (username?: string, password?: string) => {
        if (!password) {
            throw new Error('Invalid password detected');
        }
        try {
            const resp = await this.apiHandler.apiInstance.post(Endpoints.USER_LOGIN, { username, password, device: 'web' });
            const { data } = resp.data;
            return data;
        } catch(e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }

    loginWithToken = async (token?: string) => {
        if (!token) {
            throw new Error('Invalid token detected');
        }
        try {
            const user = await this.apiHandler.invokePOST(Endpoints.USER_TOKEN_LOGIN, { token })
            return user;
        } catch(e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }

    sendSMS = async (email?: string, ignoreDuplicateCheck?: boolean) => {
        try {
            const user = await this.apiHandler.invokePOST(Endpoints.SEND_SMS, { email, ignoreDuplicateCheck });
            return user;
        } catch(e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }

    sendEmailCode = async (email?: string, ignoreDuplicateCheck?: boolean, resend?: boolean) => {
        if (!this.validEmail(email)) {
            throw new Error('Invalid email detected');
        }
        try {
            const user = await this.apiHandler.invokePOST(Endpoints.SEND_SMS, { email, ignoreDuplicateCheck, resend });
            return user;
        } catch(e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }

    submitEmailCode = async (inputCode: { first: string, second: string, third: string, fourth: string }, email: string) => {
        if(!inputCode.first || !inputCode.second || !inputCode.third || !inputCode.fourth) {
            throw new Error('Invalid input code detected');
        }
        if (!this.validEmail(email)) {
            throw new Error('Invalid email detected');
        }
        const code = inputCode.first + inputCode.second + inputCode.third + inputCode.fourth;
        try {
            const resp = await this.apiHandler.apiInstance.post(Endpoints.SUBMIT_VERIFY_CODE, { code, email })
            const { data } = resp.data;
            return data;
        } catch(e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }

    loginWithPhoneNumber = async (phoneNumber?: string) => {
        if (!this.validPhoneNumber(phoneNumber)) {
            throw new Error('Invalid phone number detected');
        }
        try {
            const user = await this.apiHandler.invokePOST(Endpoints.USER_LOGIN, { email: phoneNumber });
            return user as UserInfo;
        } catch(e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }

    loginWithSocialMedia = async (email: string, provider: string) => {
        try {
            const user = await this.apiHandler.invokePOST(Endpoints.USER_SOCIAL_MEDIA_LOGIN, { email, provider });
            return user as UserInfo;
        } catch(e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }
}

export default AuthHandler;