import { useMemo, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Input, Alert } from "reactstrap";
import ReactFlagsSelect from 'react-flags-select';

import {
    CardElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import LoadingButton from "../LoadingButton";
import BillingInfo from "../../interfaces/BillingInfo";
import PaymentHandler from "../../classes/PaymentHandler";
import { StripeCardElement } from "@stripe/stripe-js";
import Coupon from "../../interfaces/Coupon";
import PaymentIntent from "../../interfaces/PaymentIntent";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { PLAN_MODE } from "../../consts/Plans";
import { getCLOnlyPrice, getCurrencyFromCountry, getOneTimePackagePrice, getSKExtraPrice, getSKMainPrice } from "./utils";
import { split, last, dropRight, join } from "lodash";
import { BillingDetailsInformation } from "../../interfaces/BillingDetails";

type Props = {
    isOpen: boolean,
    userId: number | undefined,
    usedFirstAffiliate: boolean,
    referredCode?: string | number,
    customerId: string | undefined,
    email: string | undefined,
    phone: string | undefined,
    productId: string | undefined,
    planTitle: string,
    planMode: string,
    serverUrl: string,
    country: string,
    toggle?: () => void,
    onPaymentSuccess: () => void,
    onAddNewPaymentMethod: (paymentMethod: BillingDetailsInformation) => void,
}

const StripePaymentModal = (props: Props) => {
    const [alert, setAlert] = useState('');
    const [paying, setPaying] = useState(false);
    const [billingInfo, setBillingInfo] = useState<BillingInfo>({ fullName: '', city: '', address1: '', address2: '', state: '', zipCode: '', country: props.country, coupon: '' });
    const [couponDetails, setCouponDetails] = useState<Coupon | null>(null);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const stripe = useStripe();
    const elements = useElements();

    const amountToPay = useMemo(() => {
        const splittedProdId = split(props.productId, "_");
        const count = last(splittedProdId);
        if (!count) {
            return 0;
        }
        const planId = join(dropRight(splittedProdId), '_');
        if (props.planMode === PLAN_MODE.PSK_CL) {
            return getSKMainPrice(parseInt(count), props.country) + getSKExtraPrice(planId, props.country);
        } else if (props.planMode === PLAN_MODE.CL) {
            return getCLOnlyPrice(planId, props.country)
        } else if (props.planMode === PLAN_MODE.PURCHASE_CREDITS) {
            return getOneTimePackagePrice(planId, props.country, parseInt(count));
        }
        return 0;
    }, [props.productId, props.planMode, props.country]);

    const totalPrice = useMemo(() => {
        let totalPrice = amountToPay;
        if (couponDetails) {
            if(couponDetails.percent_off) totalPrice = totalPrice * (100 - couponDetails.percent_off) / 100;
            if(couponDetails.amount_off) totalPrice = totalPrice - couponDetails.amount_off;
        }
        return totalPrice;
    }, [amountToPay, couponDetails]);

    const onClickPayNow = async () => {
        if (!stripe || !elements) {
            return;
        }
        if (!billingInfo.fullName) {
            setAlert('Invalid full name');
            return;
        }
        if (!billingInfo.city) {
            setAlert('Invalid city');
            return;
        }
        if (!billingInfo.address1) {
            setAlert('Invalid address');
            return;
        }
        if (!billingInfo.state) {
            setAlert('Invalid state');
            return;
        }
        if (!billingInfo.zipCode) {
            setAlert('Invalid zip code');
            return;
        }
        if (props.productId) {
            fetchPaymentIntentClientSecret();
        } else {
            setPaying(true);
            try {
                const paymentMethod = await stripe.createPaymentMethod({
                    type: "card",
                    card: elements.getElement("card") as StripeCardElement,
                    billing_details: {
                        address: {
                            city: billingInfo.city, 
                            country: billingInfo.country, 
                            line1: billingInfo.address1, 
                            line2: billingInfo.address2, 
                            state: billingInfo.state 
                        },
                        name: billingInfo.fullName,
                        email: props.email
                    }
                })
                const paymentMethodId = paymentMethod.paymentMethod?.id;
                if (paymentMethodId && props.customerId) {
                    const paymentHandler = new PaymentHandler<BillingDetailsInformation>(props.serverUrl);
                    const paymentMethod = await paymentHandler.attachNewPayment(props.customerId, paymentMethodId)
                    props.onAddNewPaymentMethod(paymentMethod);
                }
            } catch (e) {
                if (e instanceof Error) {
                    setAlert(e.message);
                } else if (typeof e === 'string') {
                    setAlert(e);
                }
            } finally {
                setPaying(false);
            }
        }
    }

    const fetchPaymentIntentClientSecret = async () => {
        if (!executeRecaptcha) {
            setAlert('Recaptcha challenge is not ready yet');
            return;
        }
        const token = await executeRecaptcha('payment');
        if (!token) {
            setAlert('Solving captcha is failed');
            return;
        }
        if (!props.userId) {
            setAlert('Invalid user id detected')
            return;
        }
        if (!props.productId) {
            setAlert('Invalid productId');
            return;
        }
        if (!stripe || !elements) {
            setAlert('Stripe elements not ready yet');
            return;
        }
        
        try {
            setPaying(true);
            const paymentHandler = new PaymentHandler<PaymentIntent>(props.serverUrl);
            let paymentIntent;
            if (props.planMode === PLAN_MODE.PSK_CL || props.planMode === PLAN_MODE.CL) {
                paymentIntent = await paymentHandler.fetchSubscriptionPaymentIntent(props.userId, props.productId, props.usedFirstAffiliate, props.referredCode, !!couponDetails?.id ? billingInfo.coupon : undefined, props.country === 'GB' ? 'GBP' : 'USD');
            } else {
                paymentIntent = await paymentHandler.fetchOneOffPackagePaymentIntent(props.userId, totalPrice, props.country === 'GB' ? 'GBP' : 'USD', { productId: props.productId });
            }
            
            if (paymentIntent.clientSecret) {
                const paymentResult = await stripe?.confirmCardPayment(paymentIntent.clientSecret, { 
                    payment_method: {
                        card: elements.getElement('card') as StripeCardElement,
                        billing_details: {
                            address: {
                                city: billingInfo.city, 
                                country: billingInfo.country, 
                                line1: billingInfo.address1, 
                                line2: billingInfo.address2, 
                                state: billingInfo.state 
                            },
                            name: billingInfo.fullName,
                            email: props.email,
                            phone: props.phone,
                        }
                    },
                    setup_future_usage: 'off_session'
                })
                
                setPaying(false);
                if (paymentResult.error) {
                    setAlert(paymentResult.error.message ?? 'Payment error');
                    return;
                }
                if (paymentResult.paymentIntent) {
                    props.onPaymentSuccess();
                }
            }
        } catch(e) {
            if (e instanceof Error) {
                setAlert(e.message);
            } else if (typeof e === 'string') {
                setAlert(e);
            }
            setPaying(false);
        }
    }

    const getCouponDetails = async () => {
        if (billingInfo.coupon) {
            try {
                const paymentHandler = new PaymentHandler<Coupon>(props.serverUrl);
                const coupon = await paymentHandler.getCouponDetails(billingInfo.coupon, props.customerId);
                setCouponDetails(coupon);
                setAlert('');
            } catch(e) {
                if (e instanceof Error) {
                    setAlert(e.message);
                } else if (typeof e === 'string') {
                    setAlert(e);
                }
            }
        } else {
            setCouponDetails(null);
        }
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} centered>
            <ModalHeader className='justify-content-center'>
                {props.productId ? `${props.planTitle} Card Subscription` : 'Add New Payment Method'}
            </ModalHeader>
            <ModalBody>
                <small><strong>Billing Info</strong></small>
                <Input placeholder="Full Name" className="mt-2" value={billingInfo.fullName} onChange={e => setBillingInfo(prev => ({ ...prev, fullName: e.target.value }))} />
                <Input placeholder="City" className="mt-2" value={billingInfo.city} onChange={e => setBillingInfo(prev => ({ ...prev, city: e.target.value }))} />
                <Input placeholder="Address 1" className="mt-2" value={billingInfo.address1} onChange={e => setBillingInfo(prev => ({ ...prev, address1: e.target.value }))} />
                <Input placeholder="Address 2 (Optional)" className="mt-2" value={billingInfo.address2} onChange={e => setBillingInfo(prev => ({ ...prev, address2: e.target.value }))} />
                <div className="d-flex justify-content-between mt-2">
                    <Input placeholder="State"  value={billingInfo.state} onChange={e => setBillingInfo(prev => ({ ...prev, state: e.target.value }))} />
                    <Input placeholder="Zip Code" className="ms-2" value={billingInfo.zipCode} onChange={e => setBillingInfo(prev => ({ ...prev, zipCode: e.target.value }))}/>
                </div>
                <ReactFlagsSelect 
                    className="mt-2"
                    selected={billingInfo.country}
                    onSelect={(country) => setBillingInfo(prev => ({ ...prev, country }))}
                />
                {
                    props.productId &&
                    <Input placeholder="Coupon Code (Optional)" 
                        className="mt-2" 
                        value={billingInfo.coupon} 
                        onChange={e => setBillingInfo(prev => ({ ...prev, coupon: e.target.value }))} 
                        onBlur={getCouponDetails} />
                }
                <div className="mt-2">
                    <small><strong>Card Details</strong></small>
                </div>
                <CardElement 
                    className='mt-2 bg-white p-2 border text-muted rounded'
                    options={{
                        hidePostalCode: true,
                        style: {
                            base: {
                                fontSize: '16px',
                                color: '#8898aa',
                                fontFamily: 'Poppins',
                                '::placeholder': {
                                    color: '#8898aa',
                                }
                            }
                        }
                    }} 
                />
                <hr />
                {
                    props.productId && 
                    <div>
                        <small><strong>Order Summary</strong></small>
                        <div className="d-flex justify-content-between mt-2">
                            <small>Subscription Fee</small>
                            <small>{getCurrencyFromCountry(props.country)}{(amountToPay / 100).toFixed(2)}</small>
                        </div>
                    </div>
                }
                {
                    props.productId && !!couponDetails &&
                    <div className="d-flex justify-content-between mt-2">
                        <small>Coupon Discount</small>
                        {couponDetails.percent_off && <small>-{couponDetails.percent_off}%</small>}
                        {couponDetails.amount_off && <small>- ${couponDetails.amount_off}</small>}
                    </div>
                }
                {
                    props.productId && 
                    <div className="d-flex justify-content-between mt-2">
                        <small>Total</small>
                        <small>{getCurrencyFromCountry(props.country)}{(totalPrice / 100).toFixed(2)}</small>
                    </div>
                }
                {
                    !!alert && <Alert className="mt-2" color="danger" toggle={() => setAlert('')}>{alert}</Alert>
                }
            </ModalBody>
            <ModalFooter className="justify-content-center">
                <LoadingButton onClick={onClickPayNow} loading={paying} disabled={paying}>{props.productId ? 'Pay Now' : 'Add'}</LoadingButton>
            </ModalFooter>
        </Modal>
    )
}

export default StripePaymentModal;